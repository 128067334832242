<template>
    <page class="user-page" title="我的" :hasBack="false" textAlign="center" :hasPadding="false">
        <nav-page :roleType="1">
            <div class="header">
                <img class="bg" src="@/assets/images/user/header_bg.png" alt="" />
                <div class="user-msg">
                    <img v-if="!userMsg.avatar" src="@/assets/images/user/default_avatar.png" alt="" />
<!--                    <img v-else :src="userMsg.avatar" alt="" />-->
                    <van-image v-else  class="vant-img" fit="cover" :src="userMsg.avatar" />
                    <div>
                        <div class="nickname">{{ userMsg.nickname }}</div>
                        <div class="identity">{{ userMsg.identity }}</div>
                    </div>
                </div>
                <div class="content">
                    <div class="message-wrapper">
                        <div class="message-open">
                            <img class="message-bg" src="@/assets/images/user/message_open_bg.png" alt="" />
                            <div class="close-wrapper">
                                <div class="tips">您尚未开通，消息提醒~</div>
                                <van-switch v-model="userMsg.enableService" />
                            </div>
                        </div>
                    </div>
                    <div class="my-record">
                        <div
                            class="record-item"
                            v-for="item in recordMenu"
                            :key="item.name"
                            @click="menuClick(item.link)"
                        >
                            <img :src="item.icon" alt="" />
                            <div class="title">{{ item.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="nav-wrapper">
                <div class="nav" @click="menuClick('/studentList')">
                    <div class="left">
                        <img src="@/assets/images/user/bind_student.png" class="icon" alt="" />
                        <span>绑定的学生</span>
                    </div>
                    <div class="right">
                        <span class="count">{{ userMsg.studentNum }}</span>
                        <span class="unit">人</span>
                    </div>
                </div>
                <div class="nav-list">
                    <div class="nav border" @click="menuClick('disable')">
                        <div class="left">
                            <img src="@/assets/images/user/my_card.png" class="icon" alt="" />
                            <span>我的一卡通</span>
                        </div>
                        <div class="right">
                            <van-icon name="arrow" />
                        </div>
                    </div>
                    <div class="nav" @click="menuClick('disable')">
                        <div class="left">
                            <img src="@/assets/images/user/message.png" class="icon" alt="" />
                            <span>消息推送</span>
                        </div>
                        <div class="right">
                            <van-icon name="arrow" />
                        </div>
                    </div>
                </div>
            </div>
        </nav-page>
    </page>
</template>
<script>
import Page from '@/components/page/Page.vue';
import NavPage from '@/components/navPage/NavPage';
import { reactive } from 'vue';
import { Icon, Switch, Toast } from 'vant';
import commonRequest from '@/services';
import { useRouter } from 'vue-router';
import {Image as VanImage} from "vant/lib/image";

export default {
    name: 'User',
    components: {
        [Page.name]: Page,
        [NavPage.name]: NavPage,
        [Icon.name]: Icon,
        [Switch.name]: Switch,
        [VanImage.name]: VanImage,
    },
    setup() {
        const router = useRouter();
        const recordMenu = reactive([
            {
                name: '我的请假',
                link: '/myLeave',
                icon: require('@/assets/images/user/my_leave.png'),
            },
            {
                name: '我的考勤',
                link: '/myAttendance',
                icon: require('@/assets/images/user/my_attendance.png'),
            },
            {
                name: '我的成绩',
                link: 'disable',
                icon: require('@/assets/images/user/my_score.png'),
            },
        ]);
        const userMsg = reactive({
            avatar: '',
            nickname: '帅得有点烦躁',
            identity: '家长',
            studentNum: 0,
            enableService: false,
        });
        const menuClick = (link) => {
            if (link === 'disable') {
                Toast('正在建设中');
                return;
            }
            router.push({
                path: link,
            });
        };
        commonRequest({ action: 11011 }).then((res) => {
            if (res.data) {
                userMsg.avatar = res.data.headImg;
                userMsg.nickname = res.data.name;
                userMsg.studentNum = res.data.studentNum;
                userMsg.enableService = res.data.enableService === 1;
            }
        });
        return {
            recordMenu,
            userMsg,
            menuClick,
        };
    },
};
</script>
<style lang="less" scoped>
.header {
    position: relative;
    .bg {
        width: 100%;
        margin-top: -64px;
    }
    .content {
        position: absolute;
        left: 0;
        bottom: 0;
    }
    .message-wrapper {
        padding: 0 25px;
    }
    .message-open {
        position: relative;
        .message-bg {
            width: 100%;
            display: block;
        }
    }
    .my-record {
        margin: 0 15px;
        display: flex;
        background: #ffffff;
        opacity: 1;
        border-radius: 0px 0px 5px 5px;
        height: 75px;
    }
    .record-item {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
            width: 30px;
        }
        .title {
            font-size: 12px;
            font-family: PingFang SC, serif;
            font-weight: bold;
            line-height: 17px;
            color: #333333;
            margin-top: 5px;
        }
    }
}
.nav-wrapper {
    padding: 10px 15px;
}
.nav {
    height: 51px;
    background: #ffffff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 5px;
    .left {
        display: flex;
        height: 100%;
        align-items: center;
        img {
            width: 25px;
            margin-right: 4px;
        }
        span {
            font-size: 15px;
            font-family: PingFang SC, serif;
            color: #333333;
        }
    }
    .right {
        display: flex;
        align-items: center;
        padding-right: 12px;
        .count {
            font-size: 16px;
            font-family: PingFang SC, serif;
            font-weight: 800;
            line-height: 23px;
            color: #333333;
        }
        .unit {
            font-size: 14px;
            font-family: PingFang SC, serif;
            font-weight: bold;
            line-height: 20px;
            color: #666666;
            margin-left: 5px;
        }
    }
}
.nav-list {
    margin-top: 10px;
    border-radius: 5px;
    .nav {
        border-radius: 0;
        &.border {
            position: relative;
            &::after {
                content: '';
                left: 34px;
                right: 0;
                height: 1px;
                position: absolute;
                bottom: 0;
                background: #f2f2f2;
            }
        }
    }
}
.close-wrapper {
    height: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    .tips {
        font-size: 14px;
        font-family: PingFang SC, serif;
        line-height: 20px;
        color: #ffffff;
    }
    /deep/ .van-switch {
        height: 24px;
        width: 55px;
        border: none;
        border-radius: 17px;
    }
    /deep/ .van-switch__node {
        height: 22px;
        background: #e2e2e2;
        width: 22px;
        border-radius: 50%;
        margin-top: 1px;
    }
    /deep/ .van-switch--on {
        background: #fff;
        /*background: rgba(7, 193, 96, 0.1);*/
        .van-switch__node {
            background: #07c160;
        }
    }
}
.user-msg {
    position: absolute;
    top: 27px;
    left: 17px;
    display: flex;
    align-items: center;
    img {
        width: 49px;
        margin-right: 6px;
        border-radius: 50%;
    }
    .vant-img {
        width: 49px;
        height: 49px;
        margin-right: 6px;
        /deep/ .van-image__img {
            border-radius: 50%;
        }
    }
    .nickname {
        font-size: 12px;
        font-family: PingFang SC, serif;
        font-weight: bold;
        line-height: 17px;
        color: #ffffff;
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .identity {
        width: 50px;
        height: 18px;
        background: #333333;
        border-radius: 9px;
        font-size: 12px;
        font-family: PingFang SC, serif;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        margin-top: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
